import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useRoutes, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import createThemeRoutesWithLang from './routes/Router';
import Loader from './layouts/loader/Loader';
import { clearStoreAndLogout, logout, profileUser } from './store/apps/user/userSlice';
import Hotjar from './Hotjar';
import ReactGA from 'react-ga4';
import "./assets/scss/custom-classes.css"
import { socket } from './socket';
import toast, { Toaster } from 'react-hot-toast';

const App = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'ar';  
  const location = useLocation();
 const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthAuth, users } = useSelector((state) => state.userReducer);
  ReactGA.initialize('G-XX2R3X4BMR');
  const { search } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();


  // useEffect(() => {
  //   const token = Cookies.get('token');
  //   const currentPath = window.location.pathname;
  //   const isAuthPage = currentPath.includes(`/auth/registerFormik`) || currentPath.includes(`/auth/loginFormik`) || 
  //                      currentPath.includes(`/auth/ResetPassword`) || currentPath.includes(`/auth/reset-password`) || 
  //                      currentPath.includes(`/auth/2fa`) || currentPath.includes(`/auth/forgot-password`)||
  //                      currentPath.includes(`/api/user/login/github`)|| currentPath.includes(`/api/user/login/google`)|| currentPath.includes(`/api/user/login/linkedin`);
                      

  //   // Vérification de la présence du token et de l'état d'authentification
  //   if (!token && !isAuthAuth && !isAuthPage) {
  //     dispatch(clearStoreAndLogout());

  //     const authCode = searchParams.get("authCode") ? `?authCode=${searchParams.get("authCode")}` : "";
  //     // Redirection vers la page de connexion avec la langue courante
  //     if (authCode) {
  //       navigate(`/${currentLanguage}/auth/registerFormik${authCode}`);
  //     } else {
  //       navigate(`/${currentLanguage}/auth/loginformik`);
  //     }
  //     dispatch(profileUser());
  //   }
  // }, [isAuthAuth, navigate, currentLanguage, dispatch, searchParams]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (token && isAuthAuth) {
      dispatch(profileUser());
    }
  }, [dispatch, isAuthAuth]);

  useEffect(() => {
    const currentPath = location.pathname;
    const currentLang = i18n.language;
   // Extract the part of the path that comes after the language segment
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}(?:-[A-Z]{2})?\//, '/');
    // Construct the new path with the selected language
    const newPath = `/${currentLang}${pathWithoutLang}`;
   // Only navigate if the new path is different from the current path
    if (newPath !== currentPath) {
      navigate(newPath, { replace: true });
    }
  }, [location, i18n.language, navigate]);
  
  // useEffect(() => {
  //   const token = Cookies.get('token');
  //   const currentPath = window.location.pathname;
  //   console.log(currentPath)
  //   const currentLang = i18n.language; // Obtenez la langue courante à partir de i18n
  //   const isAuthPage = currentPath.includes(`/auth/registerFormik`) || currentPath.includes(`/auth/loginFormik`)|| currentPath.includes(`/auth/ResetPassword`)|| currentPath.includes(`/auth/reset-password`)  || currentPath.includes(`/auth/2fa`) || currentPath.includes(`/auth/forgot-password`)|| currentPath.includes(`/api/user/login/github`)|| currentPath.includes(`/api/user/login/google`)|| currentPath.includes(`/api/user/login/linkedin`);
  //   if ( !token && !isAuthAuth && !isAuthPage) {
  //     dispatch(clearStoreAndLogout())
  //     const authCode = searchParams.get("authCode") ? `?authCode=${searchParams.get("authCode")}` : "";
  //     if (authCode) {
  //       navigate(`/${currentLang}/auth/registerFormik${authCode}`); // Utilisez la langue courante pour la redirection
  //     } else {
  //       navigate(`/${currentLang}/auth/loginformik`); // Utilisez la langue courante pour la redirection
  //     }
  //     return
  //   }
  //   dispatch(profileUser());
  // }, [isAuthAuth, navigate,i18n.language,dispatch]);
  // const URL = 'https://cyber-test.securas.cloud/api';

  
  useEffect(()=>{
    if(users){
      socket.connect()

      socket.on("notification", (message) => {
        toast(t => (
          <div className='d-flex gap-1 align-items-start'>
            <p className='m-0'>
              {message}
            </p>
            <button style={{all: "unset"}} type='button' onClick={() => { toast.dismiss(t.id); }}><CloseIcon /></button>
          </div>
        ),
      {
        duration: Infinity,
        style: {
          maxWidth: "800px",
          background: "#06b6d4",
          color: "white",
          fontWeight: "bold"
        }
      })
      })

      return ()=>{
        socket.off("notification")
        socket.disconnect()
      }
    }
  },[users])

  const routes = useRoutes(createThemeRoutesWithLang(i18n.language));
  // console.log(routes)

  return (
    <Suspense fallback={<Loader />}>
    <Toaster />
    <div className={`${isRTL ? 'rtl' : 'ltr'}`} dir={isRTL ? 'rtl' : 'ltr'}>
      <Hotjar/>
      <ThemeSelector />

      {routes}
    </div></Suspense>
  );
};

export default App;

function CloseIcon(){
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="white" width="20" height="20">
    <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>

}